import React from 'react'

import styled from 'styled-components'

import { color } from '../../constants'
import { MinMax } from '../../model'

interface Props {
	minMax: MinMax<number>
	value: number
}

export const VSlider: React.FC<Props> = ({ minMax: { min, max }, value }) => {
	return (
		<VSliderContainer>
			<TopLabel>{max}%</TopLabel>
			<Track></Track>
			<Thumb ratio={(value - min) / (max - min)}>{value}%</Thumb>
			<BottomLabel>{min}%</BottomLabel>
		</VSliderContainer>
	)
}

const VSliderContainer = styled.div`
	position: relative;
	width: 50px;
	height: 250px;
`

const Track = styled.div`
	position: absolute;
	top: 25px;
	left: 24px;
	width: 3px;
	height: 200px;

	background-color: ${color.gray3};
`

const TopLabel = styled.div`
	position: absolute;
	top: 2px;
	left: 17px;
`

const BottomLabel = styled.div`
	position: absolute;
	bottom: 2px;
	left: 17px;
`

const Thumb = styled.div<{ ratio: number }>`
	position: absolute;

	display: flex;
	justify-content: center;
	align-items: center;

	width: 35px;
	height: 20px;
	bottom: ${props => 25 + (props.ratio * 200)}px;
	left: 8px;

	border: 1px solid black;

	background-color: ${color.gray8};
`
