import { LapData as UdpLapData } from '@racehub-io/f1-telemetry-client/build/src/parsers/packets/types'

import { TireData } from './car'
import { Vec3 } from './position'

export interface SessionConstants {
	sessionDuration: number // seconds
	sessionType: number // enum
	aiDifficulty: number // 0-110
	totalLaps: number
	pitSpeedLimit: number
	playerCarIndex: number
	trackId: number // enum
	trackLength: number // meters
	timeOfDay: number // minutes since midnight
}

export interface DriverInfo {
	numCars: number
	drivers: Array<Driver>
}

export interface AssistInfo {
	steeringAssist: boolean
	brakingAssist: number // enum 0-3
	gearboxAssist: number // enum 1-3
	pitAssist: boolean
	pitReleaseAssist: boolean
	ersAssist: boolean
	drsAssist: boolean
	racingLineAssist: number // enum 0-2
	tractionControl: number // enum 0-2
	abs: boolean
}

export interface CarTelemetry {
	speed: number // kph
	throttle: number // 0-1
	steer: number // -1 to 1 (left to right)
	brake: number // 0-1
	clutch: number // 0-100
	gear: number // -1 to 8
	rpm: number
	revLights: number // Percent
	drs: boolean
}

export interface WheelData {
	brakeTemps: TireData // Celsius
	tireSurfaceTemps: TireData // Celsius
	tireCarcassTemps: TireData // Celsius
	engineTemp: number // Celsius
	tirePressures: TireData // PSI
	tireSurfaceTypes: TireData // enum
}

export interface CarStatus {
	fuelMix: number // enum
	brakeBias: number // 0-100
	pitLimiter: boolean
	fuelInTank: number // kg
	fuelLapsLeft: number // laps
	drsAllowed: boolean
	drsActivationDistance: number // meters
	ersStoreEnergy: number // joules
	ersDeployMode: number // enum
	ersHarvestedThisLapMGUK: number // joules
	ersHarvestedThisLapMGUH: number // joules
	ersDeployedThisLap: number // joules
}

export interface BatteryStatus {
	energy: number // joules
	ersDeployMode: number // enum
}

export interface TireStatus {
	compound: number // enum
	age: number // laps
}

export interface CarDamage {
	tireWear: TireData // 0-100
	frontLeftWingDamage: number // 0-100
	frontRightWingDamage: number // 0-100
	rearWingDamage: number // 0-100
	floorDamage: number // 0-100
	diffuserDamage: number // 0-100
	sidepodDamage: number // 0-100
	gearboxDamage: number // 0-100
	engineDamage: number // 0-100
	drsFault: boolean
	ersFault: boolean
}

export interface LiveLapData {
	lapTime: number // ms
	sector1Time: number // ms
	sector2Time: number // ms
}

export interface LapData {
	lastLapTime: number // ms
	currentLapInvalid: boolean
	currentSector: number // 1-3
	racePosition: number
	currentLap: number
	penalties: number // seconds
	warnings: number
	gridPosition: number
	numStops: number
	pitStatus: number // enum
	totalPitTime: number // ms
	pitStopTime: number // ms
	driverStatus: number // enum
	resultStatus: number // enum
}

export interface LastLapData {
	time: number
	s1: number
	s2: number
	s3: number
}

export interface LapTime {
	time: number | null
	s1: number | null
	s2: number | null
	s3: number | null
}

export const EmptyLapTime: LapTime = {
	time: null,
	s1: null,
	s2: null,
	s3: null,
}

export interface BestLapData {
	personalBest: LapTime
	sessionBest: LapTime
	sessionBestCarIdx: number | null
}

export interface WeatherForecast {
	forecastTime: number
	weather: number // enum
	trackTemp: number // celsius
	trackTempChange: number // enum
	airTemp: number // celsius
	airTempChange: number // enum
	rain: number // percent (0-100)
}

export interface SessionInfo {
	weather: number // enum
	trackTemp: number // celsius
	airTemp: number // celsius
	sessionTimeLeft: number // seconds
	safetyCarStatus: number // enum
	drsEnabled: boolean
}

export interface Driver {
	driverIdx: number
	driverId: number
	teamId: number
	nationality: number
	aiControlled: boolean
	driverName: string
}

export interface CarMotion {
	gForce: Vec3
	orientation: Vec3 // radians :: yaw, pitch, roll
	suspensionPosition: TireData
	suspensionVelocity: TireData
	suspensionAcceleration: TireData
	wheelSlip: TireData
	wheelSpeed: TireData
	velocity: Vec3
	angularVelocity: Vec3
	angularAcceleration: Vec3
	frontWheelsAngle: number // radians
}

export interface CarSetup {
	frontWing: number
	rearWing: number
	diffOnThrottle: number // percent
	diffOffThrottle: number // percent
	frontCamber: number // radians
	rearCamber: number // radians
	frontToe: number // radians
	rearToe: number // radians
	frontSuspension: number
	rearSuspension: number
	frontAntiRollBar: number
	rearAntiRollBar: number
	frontSuspensionHeight: number
	rearSuspensionHeight: number
	brakePressure: number // percent
	brakeBias: number // percent
	tirePressures: TireData
	ballast: number
	fuelLoad: number
}

export interface PitStopInfo {
	pitWindowIdealLap: number
	pitWindowLatestLap: number
	pitStopRejoinPosition: number
}

export enum DeltaType {
	Time = 'time',
	Lap = 'lap',
}

export interface Delta {
	type: DeltaType
	delta: number
}

export const EmptyDelta = {
	type: DeltaType.Time,
	delta: 0,
}

export type LapDataWithIdx = UdpLapData & { carIdx: number }

export interface WearData {
	x: number // decimal. <lapNum>.<percentDistanceInLap>
	[stintNumber: number]: number // stint # (1-*) -> percent wear (decimal; 1-100)
}

export interface StintStartData {
	startDistance: number
	startWear: number
}

export enum TireCompoundColor {
	Soft = 16,
	Medium = 17,
	Hard = 18,
	Inter = 7,
	Wet = 8,
}

export enum TireCompoundFormula {
	C5 = 16,
	C4 = 17,
	C3 = 18,
	C2 = 19,
	C1 = 20,
	Inter = 7,
	Wet = 8,
}

export interface TireCompound {
	color: TireCompoundColor
	formula: TireCompoundFormula
}

export const TireCompound = (
	color: number,
	formula: number
): TireCompound => ({
	color: color as TireCompoundColor,
	formula: formula as TireCompoundFormula,
})

export const DefaultTireCompound = TireCompound(
	TireCompoundColor.Soft,
	TireCompoundFormula.C5
)
