export class Color {
	private _r: number
	private _g: number
	private _b: number

	constructor({ r, g, b}: { r: number, g: number, b: number }) {
		this._r = r
		this._g = g
		this._b = b
	}

	public get r() { return this._r }
	public get g() { return this._g }
	public get b() { return this._b }

	public get css(): string {
		return `rgb${this.toString()}`
	}

	public interpolate(c: Color, ratio: number): Color {
		return new Color({
			r: this.getInterpolatedComponent(this.r, c.r, ratio),
			g: this.getInterpolatedComponent(this.g, c.g, ratio),
			b: this.getInterpolatedComponent(this.b, c.b, ratio),
		})
	}

	public lighten(amount?: number): Color {
		// console.log('lighten', amount)
		// console.log('this', this.toString())
		const res = new Color({
			r: Math.min(this.r + (amount ?? 10), 255),
			g: Math.min(this.g + (amount ?? 10), 255),
			b: Math.min(this.b + (amount ?? 10), 255),
		})

		// console.log('res', res.toString())
		return res
	}

	public darken(amount?: number): Color {
		return new Color({
			r: Math.max(this.r - (amount ?? 10), 0),
			g: Math.max(this.g - (amount ?? 10), 0),
			b: Math.max(this.b - (amount ?? 10), 0),
		})
	}

	static fromHex(hex: string): Color {
		const components = hex.replace(/#/,'').match(/.{1,2}/g)

		return new Color({
			r: parseInt(components![0], 16),
			g: parseInt(components![1], 16),
			b: parseInt(components![2], 16),
		})
	}

	static fromRgb(rgb: string): Color {
		const components = rgb.replace('rgb(', '').replace(')', '').split(',')

		return new Color({
			r: parseInt(components[0], 10),
			g: parseInt(components[1], 10),
			b: parseInt(components[2], 10),
		})

	}

	private getInterpolatedComponent(a: number, b: number, ratio: number): number {
		return Math.round(a + ((b - a) * ratio))
	}

	public toString(): string {
		return `(${this.r}, ${this.g}, ${this.b})`
	}
}
