import React, { useCallback } from 'react'

import { Area, AreaChart, CartesianGrid, ReferenceArea, ReferenceLine, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import styled from 'styled-components'
import { BaseUrl, color } from '../../constants'
import { TireCompound, TireCompoundColor } from '../../model/common'

import { averageWearLineSegment, currentStintState, pitStopInfoState, sessionConstantsState, stintCompoundsState, wearDataState } from '../../state'

const stintColor = (compound: TireCompound): string => {
	if (!compound) return color.white

	switch (compound.color) {
		case TireCompoundColor.Soft: return color.red
		case TireCompoundColor.Medium: return color.yellow
		case TireCompoundColor.Inter: return color.green
		case TireCompoundColor.Wet: return color.blue
		case TireCompoundColor.Hard:
		default:
			return color.white
	}
}

export const PitInfo: React.FC = () => {
	const { pitWindowIdealLap, pitWindowLatestLap, pitStopRejoinPosition } = useRecoilValue(pitStopInfoState)
	const { totalLaps } = useRecoilValue(sessionConstantsState)
	const data = useRecoilValue(wearDataState)
	const stintCompounds = useRecoilValue(stintCompoundsState)
	const currentStint = useRecoilValue(currentStintState)
	const averageWearSegment = useRecoilValue(averageWearLineSegment)
	const setWearDataState = useSetRecoilState(wearDataState)

	const clearWearData = useCallback(() => {
		fetch(`${BaseUrl}/api/clearWearHistory`, { method: 'POST' })
			.then(() => setWearDataState([]))
	}, [setWearDataState])

	if (data.length === 0) {
		return (
			<PitInfoContainer id='pit-info'>
				Loading...
			</PitInfoContainer>
		)
	}

	return (
		<PitInfoContainer id='pit-info'>
			<ResponsiveContainer width='100%' height='95%'>
				<AreaChart data={data} margin={{ bottom: 10 }}>
					<defs>
						<linearGradient id='pitWindow' x1='0' y1='1' x2='0' y2='0'>
							<stop offset='5%' stopColor={color.blueLight} stopOpacity={0.8} />
							<stop offset='95%' stopColor={color.blueLight} stopOpacity={0} />
						</linearGradient>
					</defs>
					<CartesianGrid strokeDasharray="2 4" opacity={0.2} />
					<Area dataKey='1' stroke={stintColor(stintCompounds[0])} strokeWidth={2} fill={stintColor(stintCompounds[0])} opacity={0.4} />
					<Area dataKey='2' stroke={stintColor(stintCompounds[1])} strokeWidth={2} fill={stintColor(stintCompounds[1])} opacity={0.4} />
					<Area dataKey='3' stroke={stintColor(stintCompounds[2])} strokeWidth={2} fill={stintColor(stintCompounds[2])} opacity={0.4} />
					<XAxis
						dataKey='x'
						type='number'
						// label={{ value: 'Lap', position: 'insideBottomMiddle', dy: 15, fill: color.whiteMed }}
						domain={[1, totalLaps + 1]}
						tickCount={totalLaps + 2}
						allowDecimals={false}
					/>
					<YAxis
						type='number'
						label={{ value: 'Tire Life', angle: -90, dx: -10, fill: color.whiteMed }}
						domain={[0, 100]}
					/>
					<ReferenceLine
						x={pitWindowIdealLap}
						stroke={color.green}
						strokeWidth={1}
					/>
					<ReferenceArea
						x1={pitWindowIdealLap}
						x2={Math.min(pitWindowLatestLap, totalLaps)}
						fill='url(#pitWindow)'
						fillOpacity={0.2}
						label={{ value: `Rejoin position: ${pitStopRejoinPosition}`, position: 'insideTopLeft', fill: color.whiteMed }}
					/>
					<ReferenceLine
						x={Math.min(pitWindowLatestLap, totalLaps)}
						stroke={color.orange}
						strokeWidth={1}
					/>
					<ReferenceLine
						y={30}
						stroke={color.white}
						strokeWidth={0.5}
						opacity={0.8}
						strokeDasharray='2 4'
					/>
					<ReferenceLine
						segment={averageWearSegment}
						stroke={stintColor(stintCompounds[currentStint - 1])}
						strokeWidth={1}
						strokeDasharray='2 6'
					/>
				</AreaChart>
			</ResponsiveContainer>
			<ClearDataButton onClick={() => clearWearData()}>
				Clear Data
			</ClearDataButton>
		</PitInfoContainer>
	)
}

const PitInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	align-items: center;
`

const ClearDataButton = styled.button`
	width: 100px;
	padding: 4px;
	background-color: ${color.gray3};
	border: 1px solid ${color.gray4};
	border-radius: 4px;
	color: ${color.white};
	font-family: 'Roboto Condensed';

	cursor: pointer;

	&:hover {
		background-color: ${color.gray5};
	}

	transition: all 0.08s ease-in-out;
`
