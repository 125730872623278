import { BatteryStatus, Delta } from './common'
import { TireCompoundType } from './tire'

export interface LeaderboardDriver {
	driverIdx: number
	isAi: boolean
	name: string
	gridPosition: number
	leaderDelta: Delta
	intervalDelta: Delta
	playerDelta: Delta
	tireCompound: TireCompoundType
	tireAge: number
	numStops: number
	warnings: number
	penalties: number
	driverStatus: number
	resultStatus: number
	pitStatus: number
	batteryStatus: BatteryStatus
}

export function resultStatusString(status: number): string | null {
	switch (status) {
		case 4: return 'DNF'
		case 7: return 'RET'
		default: return null
	}
}

export function driverStatusString(status: number): string | null {
	switch (status) {
		case 0: return 'Pit'
		case 2: return 'In'
		case 3: return 'Out'
		default: return null
	}
}

export function pitStatusString(status: number): string | null {
	switch (status) {
		case 1:
		case 2: return 'Pit'
		default: return null
	}
}

export function resultIconName(resultStatus: number): string | null {
	switch (resultStatus) {
		case 0: return 'penalty'
		case 3: return 'checkered-flag'
		default: return null
	}
}

/* Result Status
0: 'invalid'
1: 'inactive'
2: 'active'
3: 'finished'
4: 'dnf'
5: 'disqualified'
6: 'notClassified'
7: 'retired'
*/

/* Driver Status
0: 'garage'
1: 'flyingLap'
2: 'inLap'
3: 'outLap'
4: 'onTrack'
*/

/* Pit Status
0: 'out'
1: 'pitting'
2: 'pit'
*/
