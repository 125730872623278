import React from 'react'

import { useRecoilValue } from 'recoil'
import styled from 'styled-components'

import { Tire } from '../tires'
import { BlankTime, timeFormat } from '../../util'
import { Delta } from '../../model/common'
import { bestLapDataState, leaderboardSelector, playerLapDataSelector, sessionConstantsState } from '../../state'
import { color } from '../../constants'
import { MaxStoredEnergy, pitStatusString, resultIconName, resultStatusString } from '../../model'

export const Leaderboard: React.FC = () => {
	const { playerCarIndex } = useRecoilValue(sessionConstantsState)
	const playerLap = useRecoilValue(playerLapDataSelector)
	const drivers = useRecoilValue(leaderboardSelector)
	const { sessionBestCarIdx } = useRecoilValue(bestLapDataState)

	return (
		<LeaderboardContainer id='leaderboard'>
			<Table>
				<thead>
					<Row>
						<Header colSpan={3} />
						<Header className='borderLeft' />
						<Header className='borderLeft borderRight' colSpan={3}>Delta</Header>
						<Header className='borderLeft borderRight' />
						<Header className='borderLeft borderRight' colSpan={3}>Tire</Header>
						<Header colSpan={2} />
					</Row>
					<Row>
						<Header colSpan={3}></Header>
						<Header className='borderLeft'>Driver</Header>
						<Header className='borderLeft'>Leader</Header>
						<Header>Interval</Header>
						<Header className='borderRight'>Player</Header>
						<Header className='borderLeft borderRight'>Battery</Header>
						<Header>Comp</Header>
						<Header>Age</Header>
						<Header className='borderRight'>Pits</Header>
						<Header>Warnings</Header>
						<Header>Penalties</Header>
					</Row>
				</thead>
				<TableBody>
					{drivers.map((driver, idx) => {
						const positionChange = driver.gridPosition === 0 ? 0 : (idx + 1) - driver.gridPosition
						const statusString = resultStatusString(driver.resultStatus) || pitStatusString(driver.pitStatus)
						const resultIcon = resultIconName(driver.resultStatus)
						const batteryPercent = ((driver.batteryStatus.energy / MaxStoredEnergy) * 100).toFixed(0)
						const overtakeOn = driver.batteryStatus.ersDeployMode === 3

						return (
							<Row
								key={driver.driverIdx}
								style={{
									backgroundColor: driver.driverIdx === playerCarIndex ? color.blueLight : 'none',
								}}
							>
								<Cell width='2%'>{idx + 1}</Cell>
								<Cell width='2%'>
									{positionChange < 0 && <span style={{ color: color.greenLight }}>↑{Math.abs(positionChange)}</span>}
									{positionChange === 0 && <span>-</span>}
									{positionChange > 0 && <span style={{ color: color.red }}>↓{positionChange}</span>}
								</Cell>
								<Cell width='3%' align='center'>
									{resultIcon && (
										<img src={`icons/${resultIcon}.png`} alt={resultIcon} style={{ maxWidth: '28px' }} />
									)}
								</Cell>
								<Cell
								 width='17%'
									style={{
										backgroundColor: (driver.driverIdx === sessionBestCarIdx) ? color.purple : 'inherit',
										textOverflow: 'ellipsis',
									}}
								>
									{driver.name.toUpperCase()}
									{driver.isAi && (
										<img src={`icons/ai.png`} alt='ai' style={{ maxWidth: '16px', marginLeft: '8px' }} />
									)}
								</Cell>
								{statusString && (
									<Cell width='30%' align='center' colSpan={3}>
										{statusString.toUpperCase()}
									</Cell>
								)}
								{!statusString && (
									<>
										<Cell width='10%' align='right'>
											<DeltaTime delta={driver.leaderDelta} positive={true} />
										</Cell>
										<Cell  width='10%' align='right'>
											<DeltaTime delta={driver.intervalDelta} positive={false} />
										</Cell>
										<Cell width='10%' align='right'>
											<DeltaTime delta={driver.playerDelta} positive={idx + 1 > playerLap.racePosition} />
										</Cell>
									</>
								)}
								<Cell width='8%'>
									<div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', width: '88px' }}>
										<OTIcon active={overtakeOn}>OT</OTIcon>
										<div>{batteryPercent}%</div>
									</div>
								</Cell>
								<Cell width='3%'><Tire tireCompound={driver.tireCompound} /></Cell>
								<Cell width='3%' align='center'>{driver.tireAge}</Cell>
								<Cell width='3%' align='center'>{driver.numStops}</Cell>
								<Cell width='5%' align='center'>{driver.warnings > 0 ? `${driver.warnings}` : '-'}</Cell>
								<Cell width='5%' align='center'>{driver.penalties > 0 ? `${driver.penalties}s` : '-'}</Cell>
							</Row>
						)
					})}
				</TableBody>
			</Table>
		</LeaderboardContainer>
	)
}

const DeltaTime: React.FC<{ delta: Delta, positive: boolean }> = ({ delta, positive }) => {
	const sign = positive ? '+' : '-'
	if (delta.type === 'lap') {
		return <span>{sign}{delta.delta} lap{delta.delta > 1 ? 's' : ''}</span>
	} else {

		return <span>{!delta.delta ? BlankTime : sign + timeFormat(delta.delta)}</span>
	}
}

const Table = styled.table`
	width: 100%;
	border-collapse: collapse;

	.borderRight {
		border-right: 1px solid ${color.gray4};
	}

	.borderLeft {
		border-left: 1px solid ${color.gray4};
	}
`

const Header = styled.th`
	background-color: ${color.gray1};
	padding: 4px 0px;
`

const TableBody = styled.tbody`
	font-size: 24px;

	& > tr:nth-child(even) {
		background-color: ${color.gray4};
	}
`

const Row = styled.tr`
	& > td:first-child {
		padding-left: 12px;
	}
`

const Cell = styled.td<{ align?: 'left' | 'right' | 'center' }>`
	text-align: ${props => props.align ?? 'left'};
	padding: 4px 12px;
`

const LeaderboardContainer = styled.div`
	display: flex;
	flex-direction: column;
`

const OTIcon = styled.div<{ active: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	font-size: 14px;

	width: 20px;
	height: 20px;
	max-width: 20px;
	max-height: 20px;

	margin-right: 8px;

	background-color: ${props => props.active ? color.green : color.gray5};
	color: ${props => props.active ? color.white : color.whiteMed};
`
