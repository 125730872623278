export interface TeamInfo {
	name: string
	color: string
}

export const TeamInfo = (name: string, color: string): TeamInfo =>
	({ name, color })

export const Teams: { [teamId: number]: TeamInfo } = {
	0: TeamInfo('Mercedes', '#00D2BE'),
	1: TeamInfo('Ferrari', '#DC0000'),
	2: TeamInfo('Red Bull', '#0600EF'),
	3: TeamInfo('Williams', '#FFFFFF'),
	4: TeamInfo('Aston Martin', '#006F62'), // #020803
	5: TeamInfo('Alpine', '#0090FF'),
	6: TeamInfo('Alpha Tauri', '#2B4562'),
	7: TeamInfo('Haas', '#FFFFFF'),
	8: TeamInfo('McLaren', '#FF8700'),
	9: TeamInfo('Alfa Romeo', '#900000'),
}