export const color = {
	bg: '#1e1e1e',
	overlay: 'rgba(0, 0, 0, 0.60)',
	foreground: '#252526',
	chrome: '#333333',
	gray1: '#111111',
	gray2: '#222222',
	gray3: '#333333',
	gray4: '#444444',
	gray5: '#555555',
	gray6: '#666666',
	gray7: '#777777',
	gray8: '#888888',
	gray9: '#999999',
	green: '#00c600',
	greenDark: '#369168',
	greenLight: '#55c994',
	red: '#ca1916',
	redDark: '#a64747',
	yellow: '#e0e11d',
	orange: '#fab846',
	white: '#fff',
	whiteHigh: 'rgba(255, 255, 255, 0.87)',
	whiteMed: 'rgba(255, 255, 255, 0.60)',
	whiteLow: 'rgba(255, 255, 255, 0.38)',
	black: '#000',
	blue: '#2196f3',
	blueLight: '#51abf6',
	blueXLight: '#81c1f7',
	teal: '#6fbfc9',
	purple: '#6200ee',
	
}

// bg: '#1e1e1e',
// overlay: 'rgba(0, 0, 0, 0.60)',
// foreground: '#252526',
// chrome: '#333333',
// gray1: '#111111',
// gray2: '#222222',
// gray3: '#333333',
// gray4: '#444444',
// gray5: '#555555',
// gray6: '#666666',
// gray7: '#777777',
// gray8: '#888888',
// gray9: '#999999',
// green: '#00c600',
// greenLight: '#55c994',
// red: '#ca1916',
// yellow: '#e0e11d',
// white: '#fff',
// whiteHigh: 'rgba(255, 255, 255, 0.87)',
// whiteMed: 'rgba(255, 255, 255, 0.60)',
// whiteLow: 'rgba(255, 255, 255, 0.38)',
// black: '#000',
// blue: '#2196f3',
// blueLight: '#51abf6',
// purple: '#6200ee'



