import styled from 'styled-components'

export const Row = styled.div<{
	full?: boolean,
	flex?: string,
	direction?: 'row' | 'column',
	spaceBetween?: boolean,
	pad?: string,
	text?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl'
	stretch?: boolean,
}>`
	display: flex;
	${props => props.flex && 'flex: ' + props.flex + ';'}
	flex-direction: ${props => props.direction ?? 'row'};
	${props => props.full && 'width: 100%;'}
	justify-content: ${props => props.spaceBetween ? 'space-between' : 'center'};
	align-items: ${props => props.stretch ? 'stretch' : 'center'};
	padding: ${props => props.pad ?? '0px'};

	font-size: ${props => {
		switch(props.text) {
			case 'xs': return '10px'
			case 's': return '12px'
			case 'm': return '16px'
			case 'l': return '24px'
			case 'xl': return '30px'
			case 'xxl': return '40px'
			default: return '16px'
		}
	}};
`
