import React from 'react'

import styled from 'styled-components'

import { color } from '../../constants'
import { MinMax } from '../../model'

interface Props {
	value: number
	secondaryValue?: number
	minMax?: MinMax<number>
	height?: number
	width?: number
	barColor?: string
	negativeColor?: string
	style?: React.CSSProperties
}

export const Bar: React.FC<Props> = ({
	value,
	secondaryValue,
	minMax = MinMax(0, 100),
	height = 25,
	width = 200,
	barColor = color.white,
	negativeColor = color.gray4,
	style,
}) => {
	const { min, max } = minMax

	const rightRatio = (value - (secondaryValue ?? 0) - min) / (max - min)
	const secondaryRightRatio = ((secondaryValue ? value : min) - min) / (max - min)

	const right = Math.max((1 - rightRatio) * width, 0)
	const secondaryRight = Math.max((1 - secondaryRightRatio) * width, 0)

	return (
		<BarContainer style={style} h={height} w={width} bg={negativeColor}>
			<StyledBar color={color.green} right={secondaryRight} />
			<StyledBar color={barColor ?? color.white} right={right} />
		</BarContainer>
	)
}

const BarContainer = styled.div<{ h: number, w: number, bg: string }>`
	position: relative;
	height: ${props => props.h}px;
	width: ${props => props.w}px;

	background-color: ${props => props.bg};
`

const StyledBar = styled.div<{ right: number, color: string }>`
	position: absolute;
	left: 0px;
	top: 0px;
	bottom: 0px;
	right: ${props => props.right}px;

	background-color: ${props => props.color};
`
