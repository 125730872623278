import React from 'react'
import { useRecoilValue } from 'recoil'

import styled from 'styled-components'

import { Bar, Cell, Row } from '../common'
import { color } from '../../constants'
import { MaxStoredEnergy, MaxEnergyDeployed, deployModeString } from '../../model'
import { carStatusState } from '../../state'

export const Ers: React.FC = () => {
	const {
		ersDeployMode: deployMode,
		ersStoreEnergy: current,
		ersHarvestedThisLapMGUH: harvestedMGUH,
		ersHarvestedThisLapMGUK: harvestedMGUK,
		ersDeployedThisLap: deployed
	} = useRecoilValue(carStatusState)

	const storePercent = (current / MaxStoredEnergy) * 100
	const harvestedPercent = ((harvestedMGUH + harvestedMGUK) / MaxStoredEnergy) * 100
	const deployedPercent = (deployed / MaxEnergyDeployed) * 100

	return (
		<ErsContainer>
			<ErsHeader>
				<Cell flex='2' text='l'>ERS</Cell>
			</ErsHeader>
			<ErsBody direction='column'>
				<Row>
					<Cell flex='1' text='s'>Mode</Cell>
					<Cell flex='5'>{deployModeString(deployMode)}</Cell>
					<OTCell flex='3' enable={deployMode === 3} text='l'>OT</OTCell>
				</Row>
				<Row pad='4px 0'>
					<Row flex='2'>Store {storePercent.toFixed(0)}%</Row>
					<Cell flex='3'>
						<Bar value={storePercent} secondaryValue={harvestedPercent} />
					</Cell>
				</Row>
				<Row pad='4px 0'>
					<Row flex='2'>Deployed {deployedPercent.toFixed(0)}%</Row>
					<Cell flex='3'>
						<Bar value={deployedPercent} negativeColor={color.green} />
					</Cell>
				</Row>
			</ErsBody>
		</ErsContainer>
	)
}

const ErsContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;

	width: 100%;
	margin: 4px;
`

const ErsHeader = styled(Row)`
	border-width: 2px;
	border-style: solid;
	border-color: ${color.green};
`

const ErsBody = styled(Row)`
	border: 2px solid ${color.green};
	align-items: stretch;
	border-top: none;
`

const OTCell = styled(Cell)<{ enable: boolean }>`
	background-color: ${props => props.enable ? color.green : color.gray8 };
`
