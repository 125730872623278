export type TireCompoundType =
	| 'soft'
	| 'medium'
	| 'hard'
	| 'inter'
	| 'wet'

export const TireCompoundType = (compound: number): TireCompoundType => {
	switch (compound) {
		case 16: return 'soft'
		case 17: return 'medium'
		case 7: return 'inter'
		case 8: return 'wet'
		case 18:
		default: return 'hard'
	}
}

export enum TireSurfaceType {
	Tarmac = 0,
	RumbleStrip = 1,
	Concrete = 2,
	Rock = 3,
	Gravel = 4,
	Mud = 5,
	Sand = 6,
	Grass = 7,
	Water = 8,
	Cobblestone = 9,
	Metal = 10,
	Ridged = 11,
}

export const SurfaceTypeColor: Record<TireSurfaceType, string> = {
	[TireSurfaceType.Tarmac]: '#000000',
	[TireSurfaceType.RumbleStrip]: '#95a63f',
	[TireSurfaceType.Concrete]: '#ffffff',
	[TireSurfaceType.Rock]: '#8c8b8b',
	[TireSurfaceType.Gravel]: '#474747',
	[TireSurfaceType.Mud]: '#613b20',
	[TireSurfaceType.Sand]: '#deed93',
	[TireSurfaceType.Grass]: '#1d571e',
	[TireSurfaceType.Water]: '#2d5d87',
	[TireSurfaceType.Cobblestone]: '#c99718',
	[TireSurfaceType.Metal]: '#bfbcb4',
	[TireSurfaceType.Ridged]: '#5f2e61',
}
