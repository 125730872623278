import React from 'react'
import { useRecoilValue } from 'recoil'
import { driverInfoState, playerLapDataSelector, sessionConstantsState } from '../../state'

import { Cell, Row } from '../common'

export const Standing: React.FC = () => {
	const { totalLaps } = useRecoilValue(sessionConstantsState)
	const { currentLap, racePosition } = useRecoilValue(playerLapDataSelector)
	const { numCars } = useRecoilValue(driverInfoState)

	return (
		<Row full direction='column' pad='12px 0'>
			<Row full spaceBetween>
				<Cell text='l'>Lap</Cell>
				<Cell text='l'>Pos</Cell>
			</Row>
			<Row full>
				<Row full text='xl'>
					<Cell hAlign='end' flex='4 1 auto' text='xl'>{currentLap}</Cell>
					<Cell text='xl'>/</Cell>
					<Cell hAlign='start' flex='4 1 auto' text='xl'>{totalLaps}</Cell>
				</Row>

				<Row full>
					<Cell hAlign='end' flex='4 1 auto' text='xl'>{racePosition}</Cell>
					<Cell text='xl'>/</Cell>
					<Cell hAlign='start' flex='4 1 auto' text='xl'>{numCars}</Cell>
				</Row>
			</Row>
		</Row>
	)
}
