import React from 'react'

import { useRecoilValue } from 'recoil'
import styled from 'styled-components'
import { Bar, Row } from '../common'
import { carStatusState, telemetryState, wheelsLocked } from '../../state'
import { color } from '../../constants'

import { RevLights } from './RevLights'
import { MinMax } from '../../model'
import { kphToMph } from '../../util'

export const Dash: React.FC = () => {
	const { speed, gear, rpm, throttle, brake, revLights } = useRecoilValue(telemetryState)
	const { pitLimiter } = useRecoilValue(carStatusState)
	const lockUp = useRecoilValue(wheelsLocked)

	return (
		<DashContainer>
			<Row style={{ marginTop: '16px' }}>
				<RevLights revLights={revLights} />
			</Row>
			<SpeedSection>
				<Speed>{kphToMph(speed)} <SpeedUnit>mph</SpeedUnit></Speed>
				<Gear>{gear}</Gear>
				<Rpm>{rpm}</Rpm>
			</SpeedSection>
			<BrakeThrottleSection>
				<Bar
					style={{
						boxShadow: lockUp ? `0 0 10px 5px ${color.red}` : 'none',
					}}
					value={brake}
					barColor={color.red}
					minMax={MinMax(0, 1)}
				/>
				<Bar value={throttle} barColor={color.green} minMax={MinMax(0, 1)} />
			</BrakeThrottleSection>
			{pitLimiter && (
				<Row>
					<PitLimiterSection>
						PIT LIMIT
					</PitLimiterSection>
				</Row>
			)}
		</DashContainer>
	)
}

const DashContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	font-family: 'Formula1';
`

const Speed = styled.div`
	display: flex;
	justify-content: end;
	align-items: center;
	font-size: 24px;
`

const SpeedUnit = styled.span`
	margin-left: 4px;
	font-size: 12px;
`

const Rpm = styled.div`
	font-size: 18px;
`

const Gear = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 32px;
`

const SpeedSection = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	margin-top: 8px;

	& > *:not(:first-child) {
		margin-left: 8px;
	}

	& > * {
		flex: 1;
	}
`

const BrakeThrottleSection = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	margin-top: 8px;

	& > *:not(:first-child) {
		margin-left: 8px;
	}
`

const PitLimiterSection = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	padding: 4px 8px;

	margin-top: 12px;

	border: 3px solid ${color.yellow};
	border-radius: 6px;

	font-family: 'Roboto Condensed';
	font-size: 20px;
`
