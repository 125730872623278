import { Color } from './Color'

interface ColorInterpolatorStep {
	value: number
	color: Color
}

export const makeColorInterpolator = (steps: Array<ColorInterpolatorStep>) => {
	if (steps.length < 2) throw new Error('Not enough steps to make a color interpolator')
	
	const sortedSteps = [...steps].sort((a, b) => a.value - b.value)
	const lowStep = sortedSteps[0]
	const highStep = sortedSteps[sortedSteps.length - 1]

	return (value: number): string => {
		if (value <= lowStep.value) return lowStep.color.css
		else if (value >= highStep.value) return highStep.color.css
		else {
			let idx = 0

			for (let i = 0; i < sortedSteps.length - 1; i++) {
				idx = i
				if (value < sortedSteps[i + 1].value) break
			}

			const a = sortedSteps[idx]
			const b = sortedSteps[idx + 1]

			const ratio = (value - a.value) / (b.value - a.value)

			const res = a.color.interpolate(b.color, ratio).css

			return res
		}
	}
}
