import React from 'react'
import { useRecoilValue } from 'recoil'
import styled from 'styled-components'

import { color } from '../../constants'
import { CarSide, SurfaceTypeColor, TireSurfaceType } from '../../model'
import { carDamageState, wheelDataState, wheelLockUpSelector, wheelSlipSelector } from '../../state'
import { brakeTempColorInterpolator, tireSurfaceTempColorInterpolator, tireCarcassTempColorInterpolator, tireWearColorInterpolator } from '../../util'

interface Props {
	wheelIdx: number
}

export const Wheel: React.FC<Props> = ({
	wheelIdx,
}) => {
	const { tireSurfaceTemps, tireCarcassTemps, brakeTemps, tireSurfaceTypes } = useRecoilValue(wheelDataState)
	const { tireWear } = useRecoilValue(carDamageState)
	const wheelLocked = useRecoilValue(wheelLockUpSelector(wheelIdx))
	const wheelSlip = useRecoilValue(wheelSlipSelector(wheelIdx))

	const surfaceTemp = tireSurfaceTemps[wheelIdx]
	const carcassTemp = tireCarcassTemps[wheelIdx]
	const wear = tireWear[wheelIdx]
	const brakeTemp = brakeTemps[wheelIdx]
	const surfaceType = tireSurfaceTypes[wheelIdx] as TireSurfaceType

	return (
		<WheelContainer>
			<TireContainer>
				<Surface
					style={{
						backgroundColor: tireSurfaceTempColorInterpolator(surfaceTemp),
						boxShadow: surfaceTemp > 100 ? `0 0 10px 10px ${color.red}` : 'none',
					}}
				>
					{surfaceTemp}°
				</Surface>
				<Carcass style={{ backgroundColor: tireCarcassTempColorInterpolator(carcassTemp) }}>
					{carcassTemp}°
				</Carcass>
				<Brake style={{ backgroundColor: brakeTempColorInterpolator(brakeTemp) }}>
					{brakeTemp}°
				</Brake>
			</TireContainer>
			<WearContainer>
				<Wear
					style={{
						backgroundColor: tireWearColorInterpolator(wear),
						boxShadow: wheelLocked ? `0 0 10px 10px ${color.red}` : 'none',
						borderWidth: '8px',
						borderStyle: 'solid',
						marginBottom: '4px',
						borderColor: SurfaceTypeColor[surfaceType],
					}}
				>
					{wear.toFixed(0)}%
				</Wear>
				<div>{(wheelSlip * 100).toFixed(2)}%</div>
			</WearContainer>
		</WheelContainer>
	)
}

const WheelContainer = styled.div`
	display: flex;
	flex-direction: row;
	margin: 12px;
	/* font-family: 'Titillium Web'; */
	font-weight: 600;
`

const TireContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: stretch;

	align-self: stretch;
	min-width: 150px;
`

const WearContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-left: 20px;
`

const ValueContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 32px;

	width: 100%;
`

const Surface = styled(ValueContainer)`
	border-bottom: 1px solid ${color.bg};
`

const Brake = styled(ValueContainer)`
	margin-top: 12px;
`

const Carcass = styled(ValueContainer)`
`

const Wear = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	/* padding: 30px; */

	font-size: 32px;

	width: 100px;
	height: 100px;

	border-radius: 50%;
`
