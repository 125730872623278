import React from 'react'

import styled from 'styled-components'

import { Wheel } from './Wheel'

interface Props {}

export const Wheels: React.FC<Props> = () => {
	return (
		<WheelsContainer id='wheels'>
			<WheelRow>
				<Wheel wheelIdx={2} />
				<Wheel wheelIdx={3} />
			</WheelRow>
			<WheelRow>
				<Wheel wheelIdx={0} />
				<Wheel wheelIdx={1} />
			</WheelRow>
		</WheelsContainer>
	)
}

const WheelsContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

const WheelRow = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
`
