import React from 'react'
import { useRecoilValue } from 'recoil'

import styled from 'styled-components'

import { Cell, Row } from '../common'
import { color } from '../../constants'
import { carStatusState, sessionInfoState, telemetryState } from '../../state'

export const Drs: React.FC = () => {
	const { drsActivationDistance } = useRecoilValue(carStatusState)
	const { drsEnabled } = useRecoilValue(sessionInfoState)

	return (
		<DrsContainer>
			<DrsHeader text='l' allowed={drsEnabled}>DRS</DrsHeader>
			<DrsBody allowed={drsEnabled}>
				{/* <DrsBodyCell enable={drsActivationDistance > 0 || wingOpen}> */}
				<DrsBodyCell enable={drsActivationDistance > 0}>
					<Row text='s'>Available</Row>
					<Row text='l'>{drsActivationDistance > 0 ? `${drsActivationDistance}m` : 'No'}</Row>
				</DrsBodyCell>
				<WingOpenCell />
			</DrsBody>
		</DrsContainer>
	)
}

export const WingOpenCell: React.FC = () => {
	const { drs: wingOpen } = useRecoilValue(telemetryState)

	return (
		<DrsBodyCell text='xl' enable={wingOpen}>
			{wingOpen ? 'Open' : 'Closed'}
		</DrsBodyCell>
	)
}

const DrsContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	flex: 1;

	width: 100%;
	margin: 4px;
`

const DrsHeader = styled(Row)<{ allowed: boolean }>`
	border-width: 2px;
	border-style: solid;
	border-color: ${props => props.allowed ? color.green : color.gray8 };
	background-color: ${props => props.allowed ? color.green : color.gray8 };
`

const DrsBody = styled(Row)<{ allowed: boolean }>`
	border-width: 2px;
	border-style: solid;
	border-color: ${props => props.allowed ? color.green : color.gray8 };

	align-items: stretch;

	border-top: none;
`

const DrsBodyCell = styled(Cell)<{ enable: boolean }>`
	border-width: 2px;
	border-style: solid;
	border-color: ${props => props.enable ? color.green : color.gray8 };
	background-color: ${props => props.enable ? color.green : color.gray8 };


	border-top: none;
`
