import React from 'react'

import styled from 'styled-components'

import { Cell, Row } from '../common'
import { color } from '../../constants'
import { CurrentLapTime, TimeDelta } from '../../model'
import { LapTime } from '../../model/common'
import { timeFormat } from '../../util'

interface Props {
	heading: string
	current: CurrentLapTime | null
	sessionBest: LapTime
	personalBest: LapTime
	currentSector: number
	isCurrentLap: boolean
}

export const SectorTimes: React.FC<Props> = ({ heading, current, sessionBest, personalBest, currentSector, isCurrentLap }) => {
	const s1Active = isCurrentLap && currentSector === 1
	const s2Active = isCurrentLap && currentSector === 2
	const s3Active = isCurrentLap && currentSector === 3

	return (
		<SectorTimesContainer>
			<Row full text='l' pad='12px 0'>{heading}</Row>
			<Row full>
				<SectorTimeHeader
					delta={TimeDelta(
						s1Active,
						current?.s1 ?? undefined,
						sessionBest.s1 ?? undefined,
						personalBest.s1 ?? undefined
					)}
					active={s1Active}
				>
					S1
				</SectorTimeHeader>
				<SectorTimeHeader
					delta={TimeDelta(
						s2Active,
						current?.s2 ?? undefined,
						sessionBest.s2 ?? undefined,
						personalBest.s2 ?? undefined
					)}
					active={s2Active}
				>
					S2
				</SectorTimeHeader>
				<SectorTimeHeader
					delta={TimeDelta(
						s3Active,
						current?.s3 ?? undefined,
						sessionBest.s3 ?? undefined,
						personalBest.s3 ?? undefined
					)}
					active={s3Active}
				>
					S3
				</SectorTimeHeader>
				<SectorTimeHeader
					delta={TimeDelta(
						isCurrentLap,
						current?.time,
						sessionBest.time ?? undefined,
						personalBest?.time ?? undefined
					)}
					active={false}
				>
					Lap
				</SectorTimeHeader>
			</Row>
			<Row full>
				<Cell text='xl' flex='1' hAlign='center'>{timeFormat(current?.s1 ?? undefined)}</Cell>
				<Cell text='xl' flex='1'>{timeFormat(current?.s2 ?? undefined)}</Cell>
				<Cell text='xl' flex='1'>{timeFormat(current?.s3 ?? undefined)}</Cell>
				<Cell text='xl' flex='1'>{timeFormat(current?.time)}</Cell>
			</Row>
		</SectorTimesContainer>
	)
}

const SectorTimesContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;

	margin-bottom: 12px;
`

const SectorTimeHeader = styled(Cell)<{ delta: TimeDelta, active: boolean }>`
	border: 1px solid ${color.gray5};
	padding: 4px 0;
	flex: 1;

	${({ active }) => active && `color: ${color.blueLight};`}

	background-color: ${props => {
		switch(props.delta) {
			case 'sessionBest': return color.purple
			case 'personalBest': return color.green
			case 'under': return color.yellow
			case 'slow': return color.red
			default: return color.gray4
		}
	}};
`
