export const MaxStoredEnergy = 4000000
export const MaxEnergyDeployed = 4000000

export const deployModeString = (deployMode: number): string => {
	switch (deployMode) {
		case 1: return 'Medium'
		case 2: return 'Hotlap'
		case 3: return 'Overtake'
		default: return 'None'
	}
}
