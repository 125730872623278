export interface Vec {
	x: number
	y: number
}

export const Vec = (
	x: number,
	y?: number
): Vec => ({ x, y: y ?? x })

export interface Vec3 {
	x: number
	y: number
	z: number
}

export const Vec3 = (
	x: number,
	y?: number,
	z?: number
): Vec3 => ({ x, y: y ?? x, z: z ?? x })
