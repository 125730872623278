import { MinMax } from './car'
import { Vec3 } from './common/position'

export const MapSize = 600
export const CarIconSize = 15

export interface AxisTranslation {
	flipped: boolean
	offset: number
}

export const AxisTranslation = (flipped: boolean, offset: number): AxisTranslation =>
	({ flipped, offset })

export interface PositionCalculatorConfig {
	minMax: MinMax<number>
	scale: number,
	xTranslation: AxisTranslation
	yTranslation: AxisTranslation
}

export const PositionCalculatorConfig = (minMax: MinMax<number>, scale: number, xTranslation: AxisTranslation, yTranslation: AxisTranslation): PositionCalculatorConfig =>
	({ minMax, scale, xTranslation, yTranslation })

export const prettyPos = (p: Vec3): string => `${p.x.toFixed(2)}, ${p.z.toFixed(2)}`

export type PositionCalculator = (x: number, y: number) => [number, number]

/**
 * Axis directions are defined as follows:
 *    +y
 * -x -|- +x
 *    -y
 */
export const makePositionCalculator = (
	{ minMax, scale, xTranslation, yTranslation }: PositionCalculatorConfig,
	boundSize: number
) => (x: number, y: number): [number, number] => {

	const scaledRatio = (value: number, minMax: MinMax<number>, scale: number): number => {
		return (value - (minMax.min * scale)) / ((minMax.max * scale) - (minMax.min * scale))
	}

	const getPos = (n: number, translation: AxisTranslation): number => {
		return ((translation.flipped ? -1 : 1) * n) + translation.offset
	}

	const getRatio = (value: number, translation: AxisTranslation): number => {
		const ratio = scaledRatio(value, minMax, scale)
		return translation.flipped ? 1 - ratio : ratio
	}

	const xPos = getPos(x, xTranslation)
	const yPos = getPos(y, yTranslation)
	const xRatio = getRatio(xPos, xTranslation)
	const yRatio = getRatio(yPos, yTranslation)

	return [xRatio * boundSize, yRatio * boundSize]
}

export interface TrackMapConfig {
	name: string
	calculatorConfig: PositionCalculatorConfig
}

export const TrackMapConfig = (
	name: string,
	calculatorConfig: PositionCalculatorConfig
): TrackMapConfig => ({ name, calculatorConfig })

export const trackMapDebugBounds: { [trackId: number]: { left: number, right: number, top: number, bottom: number } } = {
	1: { left: -1069, right: 1018, top: -654, bottom: 567 }, // France
	17: { left: -536, right: 737, top: -485, bottom: 310 }, // Austria
}

export const trackMapConfigs: { [trackId: number]: TrackMapConfig } = {
	0: TrackMapConfig('australia', PositionCalculatorConfig(
		MinMax(-1000, 1000),
		1,
		AxisTranslation(false, 0),
		AxisTranslation(false, 0)
	)),
	1: TrackMapConfig('france', PositionCalculatorConfig( // Needs rotation
		MinMax(-1100, 1100),
		1,
		AxisTranslation(false, 0),
		AxisTranslation(false, 0)
	)),
	2: TrackMapConfig('china', PositionCalculatorConfig(
		MinMax(-1000, 1000),
		1,
		AxisTranslation(false, 0),
		AxisTranslation(false, 0)
	)),
	3: TrackMapConfig('bahrain', PositionCalculatorConfig(
		MinMax(-1000, 1000),
		1,
		AxisTranslation(false, 0),
		AxisTranslation(false, 0)
	)),
	4: TrackMapConfig('spain', PositionCalculatorConfig(
		MinMax(-1000, 1000),
		1,
		AxisTranslation(false, 0),
		AxisTranslation(false, 0)
	)),
	5: TrackMapConfig('monaco', PositionCalculatorConfig(
		MinMax(-1000, 1000),
		1,
		AxisTranslation(false, 0),
		AxisTranslation(false, 0)
	)),
	6: TrackMapConfig('canada', PositionCalculatorConfig(
		MinMax(-1500, 1500),
		1,
		AxisTranslation(false, 0),
		AxisTranslation(false, 0)
	)),
	7: TrackMapConfig('silverstone', PositionCalculatorConfig(
		MinMax(-1000, 1000),
		1,
		AxisTranslation(false, 0),
		AxisTranslation(false, 0)
	)),
	8: TrackMapConfig('germany', PositionCalculatorConfig(
		MinMax(-1000, 1000),
		1,
		AxisTranslation(false, 0),
		AxisTranslation(false, 0)
	)),
	9: TrackMapConfig('hungary', PositionCalculatorConfig(
		MinMax(-1000, 1000),
		1,
		AxisTranslation(false, 0),
		AxisTranslation(false, 0)
	)),
	10: TrackMapConfig('spa', PositionCalculatorConfig(
		MinMax(-1000, 1000),
		1,
		AxisTranslation(false, 0),
		AxisTranslation(false, 0)
	)),
	11: TrackMapConfig('monza', PositionCalculatorConfig(
		MinMax(-1000, 1000),
		1,
		AxisTranslation(false, 0),
		AxisTranslation(false, 0)
	)),
	12: TrackMapConfig('singapore', PositionCalculatorConfig(
		MinMax(-1000, 1000),
		1,
		AxisTranslation(false, 0),
		AxisTranslation(false, 0)
	)),
	13: TrackMapConfig('japan', PositionCalculatorConfig(
		MinMax(-1000, 1000),
		1,
		AxisTranslation(false, 0),
		AxisTranslation(false, 0)
	)),
	14: TrackMapConfig('abu-dhabi', PositionCalculatorConfig(
		MinMax(-1000, 1000),
		1,
		AxisTranslation(false, 0),
		AxisTranslation(false, 0)
	)),
	15: TrackMapConfig('austin', PositionCalculatorConfig(
		MinMax(-1000, 1000),
		1,
		AxisTranslation(false, 0),
		AxisTranslation(false, 0)
	)),
	16: TrackMapConfig('brazil', PositionCalculatorConfig(
		MinMax(-1000, 1000),
		1,
		AxisTranslation(false, 0),
		AxisTranslation(false, 0)
	)),
	17: TrackMapConfig('austria', PositionCalculatorConfig(
		MinMax(-750, 750),
		0.94,
		AxisTranslation(false, -100),
		AxisTranslation(true, -85)
	)),
	18: TrackMapConfig('russia', PositionCalculatorConfig(
		MinMax(-1000, 1000),
		1,
		AxisTranslation(false, 0),
		AxisTranslation(false, 0)
	)),
	19: TrackMapConfig('mexico', PositionCalculatorConfig(
		MinMax(-1000, 1000),
		1,
		AxisTranslation(false, 0),
		AxisTranslation(false, 0)
	)),
	20: TrackMapConfig('azerbaijan', PositionCalculatorConfig(
		MinMax(-1000, 1000),
		1,
		AxisTranslation(false, 0),
		AxisTranslation(false, 0)
	)),
	21: TrackMapConfig('bahrain-short', PositionCalculatorConfig(
		MinMax(-1000, 1000),
		1,
		AxisTranslation(false, 0),
		AxisTranslation(false, 0)
	)),
	22: TrackMapConfig('silverstone-short', PositionCalculatorConfig(
		MinMax(-1000, 1000),
		1,
		AxisTranslation(false, 0),
		AxisTranslation(false, 0)
	)),
	23: TrackMapConfig('austin-short', PositionCalculatorConfig(
		MinMax(-1000, 1000),
		1,
		AxisTranslation(false, 0),
		AxisTranslation(false, 0)
	)),
	24: TrackMapConfig('japan-short', PositionCalculatorConfig(
		MinMax(-1000, 1000),
		1,
		AxisTranslation(false, 0),
		AxisTranslation(false, 0)
	)),
	25: TrackMapConfig('vietnam', PositionCalculatorConfig(
		MinMax(-1000, 1000),
		1,
		AxisTranslation(false, 0),
		AxisTranslation(false, 0)
	)),
	26: TrackMapConfig('zandvoort', PositionCalculatorConfig(
		MinMax(-1000, 1000),
		1,
		AxisTranslation(false, 0),
		AxisTranslation(false, 0)
	)),
	27: TrackMapConfig('imola', PositionCalculatorConfig(
		MinMax(-1000, 1000),
		1,
		AxisTranslation(false, 0),
		AxisTranslation(false, 0)
	)),
	28: TrackMapConfig('portimao', PositionCalculatorConfig(
		MinMax(-1000, 1000),
		1,
		AxisTranslation(false, 0),
		AxisTranslation(false, 0)
	)),
	29: TrackMapConfig('jeddah', PositionCalculatorConfig(
		MinMax(-1000, 1000),
		1,
		AxisTranslation(false, 0),
		AxisTranslation(false, 0)
	)),
	30: TrackMapConfig('miami', PositionCalculatorConfig(
		MinMax(-1000, 1000),
		1,
		AxisTranslation(false, 0),
		AxisTranslation(false, 0)
	)),
}
