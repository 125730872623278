import React from 'react'

import { useRecoilValue } from 'recoil'
import styled from 'styled-components'

import { color } from '../../constants'
import { safetyCarString, sessionTypeString, trackNameString, weatherIconName } from '../../model'
import { sessionConstantsState, sessionInfoState } from '../../state'
import { kphToMph, secondTimeFormat } from '../../util'
import { Row } from '../common'

export const SessionInfo: React.FC = () => {
	const { sessionType, aiDifficulty, pitSpeedLimit, trackId, timeOfDay } = useRecoilValue(sessionConstantsState)
	const { weather, trackTemp, airTemp, sessionTimeLeft, safetyCarStatus } = useRecoilValue(sessionInfoState)

	const weatherIcon = weatherIconName(weather, timeOfDay)

	const safetyCar = safetyCarString(safetyCarStatus)
	// const safetyCar = safetyCarString(3)

	return (
		<SessionInfoContainer id='session-info'>
			<Row full spaceBetween>
				<div style={{ flex: '1', fontSize: '32px'  }}>
					{sessionTypeString(sessionType)}
				</div>
				<div style={{ textAlign: 'center', flex: '1', fontSize: '24px' }}>
					{trackNameString(trackId)}
				</div>
				<div style={{ flex: '1', display: 'flex', justifyContent: 'end', fontSize: '32px' }}>
					{secondTimeFormat(sessionTimeLeft)}
				</div>
			</Row>
			{safetyCar !== null && (
				<Row style={{ marginTop: '12px', marginBottom: '12px' }}>
					<div style={{ border: `3px solid ${color.yellow}`, borderRadius: '6px', color: color.yellow, fontSize: '40px', fontWeight: 600, padding: '8px' }}>
						{safetyCar}
					</div>
				</Row>
			)}
			<Row full spaceBetween style={{ marginTop: '48px' }}>
				<ConditionsHeader>Track Temp</ConditionsHeader>
				<ConditionsHeader>Weather</ConditionsHeader>
				<ConditionsHeader>Air Temp</ConditionsHeader>
			</Row>
			<Row full spaceBetween>
				<ConditionsCell>{trackTemp}°</ConditionsCell>
				<ConditionsCell><img style={{ margin: '-8px' }} src={`weather/${weatherIcon}.png`} alt={weatherIcon} /></ConditionsCell>
				<ConditionsCell>{airTemp}°</ConditionsCell>
			</Row>
			<Row full spaceBetween style={{ marginTop: '48px' }}>
				<ConditionsHeader>AI Difficulty</ConditionsHeader>
				<ConditionsHeader>Pit SpeedLimit</ConditionsHeader>
			</Row>
			<Row full spaceBetween>
				<ConditionsCell>{aiDifficulty}</ConditionsCell>
				<ConditionsCell>{kphToMph(pitSpeedLimit)} mph</ConditionsCell>
			</Row>
		</SessionInfoContainer>
	)
}

const SessionInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;

	padding: 8px;
`

const ConditionsCell = styled.div`
	display: flex;
	justify-content: center;
	flex: 1;
	font-size: 24px;
`

const ConditionsHeader = styled(ConditionsCell)`
	font-size: 18px;
`
