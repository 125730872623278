import styled from 'styled-components'

export const Cell = styled.div<{
	full?: boolean,
	flex?: string,
	hAlign?: string,
	pad?: string,
	text?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl',
	stretch?: boolean,
}>`
	display: flex;
	flex: ${props => props.flex ?? '1 1 auto'};
	flex-direction: column;
	align-items: ${props => props.hAlign ?? 'center'};
	justify-content: ${props => props.stretch ? 'stretch' : 'center'};
	padding: ${props => props.pad ?? '0px'};

	font-size: ${props => {
		switch(props.text) {
			case 'xs': return '10px'
			case 's': return '12px'
			case 'm': return '16px'
			case 'l': return '24px'
			case 'xl': return '32px'
			case 'xxl': return '40px'
			default: return '16px'
		}
	}};
`
