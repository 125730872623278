import React from 'react'

import styled from 'styled-components'
import { color } from '../../constants'

interface Props {
	revLights: number
}

const lightColors: Array<string> = [
	color.green,
	color.green,
	color.green,
	color.green,
	color.green,
	color.red,
	color.red,
	color.red,
	color.red,
	color.red,
	color.purple,
	color.purple,
	color.purple,
	color.purple,
	color.purple,
]

export const RevLights: React.FC<Props> = ({ revLights }) => {
	return (
		<RevLightsContainer>
			{lightColors.map((lightColor, index) => {
				const active = ((revLights & 1) !== 0) || ((revLights & (2 << index - 1)) !== 0)
				return (
					<RevLight
						key={index}
						style={{
							backgroundColor: active ? lightColor : color.gray3,
							boxShadow: active ? `0 0 3px 1px ${lightColor}` : 'none'
						}}
					/>
				)
			})}
		</RevLightsContainer>
	)
}

const RevLightsContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	& > *:not(:first-child) {
		margin-left: 8px;
	}
`

const RevLight = styled.div`
	width: 8px;
	height: 8px;
	border-radius: 50%;
`
