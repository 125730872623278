import React from 'react'

import { TireCompoundType } from '../../model'

import styled from 'styled-components'

import tireSoft from '../../assets/tire-soft.svg'
import tireMedium from '../../assets/tire-medium.svg'
import tireHard from '../../assets/tire-hard.svg'

interface Props {
	tireCompound: TireCompoundType
}

export const Tire: React.FC<Props> = ({ tireCompound }) => {
	const svg = tireCompound === 'soft'
		? tireSoft
		: tireCompound === 'medium'
			? tireMedium
			: tireHard

	return (
		<TireContainer>
			<img src={svg} alt={tireCompound} />
		</TireContainer>
	)
}

const TireContainer = styled.div`
	display: flex;
	max-height: 24px;
`
