import React from 'react'

import { useRecoilValue } from 'recoil'
import styled from 'styled-components'

import { Bar, Cell, Row } from '../common'
import { color } from '../../constants'
import {
	MinMax,
	aeroMinMax,
	ballastMinMax,
	diffMinMax,
	frontCamberMinMax,
	rearCamberMinMax,
	frontToeMinMax,
	rearToeMinMax,
	suspensionMinMax,
	brakePressureMinMax,
	brakeBiasMinMax,
	frontTireMinMax,
	rearTireMinMax,
} from '../../model'
import { assistInfoState, carSetupState } from '../../state'

export const CarSetupView: React.FC = () => {
	const {
		frontWing,
		rearWing,
		diffOnThrottle,
		diffOffThrottle,
		frontCamber,
		rearCamber,
		frontToe,
		rearToe,
		frontSuspension,
		rearSuspension,
		frontAntiRollBar,
		rearAntiRollBar,
		frontSuspensionHeight,
		rearSuspensionHeight,
		brakePressure,
		brakeBias,
		tirePressures,
		ballast,
	} = useRecoilValue(carSetupState)
	const {
		gearboxAssist,
		pitAssist,
		pitReleaseAssist,
		ersAssist,
		tractionControl,
		abs,
	} = useRecoilValue(assistInfoState)

	return (
		<CarSetupViewContainer id='car-setup'>
			<Row>
				<CarSetupViewCell text='l'>Car Setup</CarSetupViewCell>
			</Row>
			<Row>
				<Cell flex='1'>Gearbox: {gearboxAssistString(gearboxAssist)}</Cell>
				<Cell flex='1'>TC: {tcAssistString(tractionControl)}</Cell>
				<Cell flex='1'>ABS: {toggleAssistString(abs)}</Cell>
			</Row>
			<Row>
				<Cell flex='1'>ERS Assist: {toggleAssistString(ersAssist)}</Cell>
				<Cell flex='1'>Pit Assist: {toggleAssistString(pitAssist)}</Cell>
				<Cell flex='1'>Pit Release Assist: {toggleAssistString(pitReleaseAssist)}</Cell>
			</Row>
			<Row>
				<CarSetupViewCell flex='1'>Aero</CarSetupViewCell>
				<CarSetupViewCell flex='1'>Differential</CarSetupViewCell>
			</Row>
			<Row>
				<BarCell minMax={aeroMinMax} value={frontWing} label='Front Wing' />
				<BarCell minMax={diffMinMax} value={diffOnThrottle} label='On Throttle' unit='%' />
			</Row>
			<Row>
				<BarCell minMax={aeroMinMax} value={rearWing} label='Rear Wing' />
				<BarCell minMax={diffMinMax} value={diffOffThrottle} label='Off Throttle' unit='%' />
			</Row>
			<Row>
					<CarSetupViewCell flex='1'>Suspension Geometry</CarSetupViewCell>
			</Row>
			<Row>
				<BarCell minMax={frontCamberMinMax} value={frontCamber} label='Front Camber' unit='°' />
				<BarCell minMax={rearCamberMinMax} value={rearCamber} label='Rear Camber'  unit='°' />
			</Row>
			<Row>
				<BarCell minMax={frontToeMinMax} value={frontToe} valueDisplay={frontToe.toFixed(2)} label='Front Toe'  unit='°' />
				<BarCell minMax={rearToeMinMax} value={rearToe} valueDisplay={rearToe.toFixed(2)} label='Rear Toe'  unit='°' />
			</Row>
			<Row>
				<CarSetupViewCell flex='1'>Suspension</CarSetupViewCell>
			</Row>
			<Row>
				<BarCell minMax={suspensionMinMax} value={frontSuspension} label='Front Suspension' />
				<BarCell minMax={suspensionMinMax} value={rearSuspension} label='Rear Suspension' />
			</Row>
			<Row>
				<BarCell minMax={suspensionMinMax} value={frontAntiRollBar} label='Front Anti-Roll Bar' />
				<BarCell minMax={suspensionMinMax} value={rearAntiRollBar} label='Rear Anti-Roll Bar' />
			</Row>
			<Row>
				<BarCell minMax={suspensionMinMax} value={frontSuspensionHeight} label='Front Ride Height' />
				<BarCell minMax={suspensionMinMax} value={rearSuspensionHeight} label='Rear Ride Height' />
			</Row>
			<Row>
				<CarSetupViewCell flex='1'>Brakes</CarSetupViewCell>
			</Row>
			<Row>
				<BarCell minMax={brakePressureMinMax} value={brakePressure} label='Brake Pressure' unit='%' />
				<BarCell minMax={brakeBiasMinMax} value={brakeBias} label='Front Brake Bias' unit='%' />
			</Row>
			<Row>
				<CarSetupViewCell flex='1'>Tire Pressure</CarSetupViewCell>
			</Row>
			<Row>
				<BarCell minMax={frontTireMinMax} value={tirePressures[2]} label='Front Left' unit='psi' />
				<BarCell minMax={frontTireMinMax} value={tirePressures[3]} label='Front Right' unit='psi' />
			</Row>
			<Row>
				<BarCell minMax={rearTireMinMax} value={tirePressures[0]} label='Rear Left' unit='psi' />
				<BarCell minMax={rearTireMinMax} value={tirePressures[1]} label='Rear Right' unit='psi' />
			</Row>
			<Row>
				<BarCell minMax={ballastMinMax} value={ballast} label='Ballast' unit='kg' />
			</Row>
		</CarSetupViewContainer>
	)
}

const gearboxAssistString = (gearboxAssist: number) => {
	switch (gearboxAssist) {
		case 1:
		case 2:
			return 'Manual'
		case 3:
		default:
			return 'Auto'
	}
}

const tcAssistString = (tcAssist: number) => {
	switch (tcAssist) {
		case 0: return 'Full'
		case 1: return 'Medium'
		case 2:
		default:
			return 'Off'
	}
}

const toggleAssistString = (assist: boolean) => {
	return assist ? 'On' : 'Off'
}

interface BarCellProps {
	minMax: MinMax<number>
	value: number
	valueDisplay?: string
	label: string
	unit?: string
}

export const BarCell: React.FC<BarCellProps> = ({ minMax, value, valueDisplay, label, unit }) => {
	return (
		<BarCellContainer id='zoomy' flex='1' pad='2px'>
			<Row spaceBetween>
				<Cell flex='4' text='s' hAlign='start'>{label}</Cell>
				<Cell flex='1' text='s' hAlign='end'>{valueDisplay ?? value}{unit}</Cell>
				<Cell flex='4'>
					<Bar minMax={minMax} negativeColor={color.gray3} value={value} width={75} height={9} />
				</Cell>
			</Row>
		</BarCellContainer>
	)
}

const CarSetupViewContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	box-sizing: border-box;
`

const BarCellContainer = styled(Cell)`
	align-items: stretch;
`

const CarSetupViewCell = styled(Cell)`
	padding: 12px 0 4px 0;
`
