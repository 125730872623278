export const BlankTime = '--:--.---'

export const timeFormat = (time?: number, hideMs: boolean = false): string => {
	if (!time) return BlankTime

	const sec = Math.trunc((Math.abs(time) % 60000) / 1000)
	const min = Math.trunc(time / 60000)

	const msPart = '.' + (Math.abs(time) % 1000).toString().padStart(3, '0')
	const secPart = min <= 0
		? sec === 0
			? ''
			: sec.toString()
		: sec.toString().padStart(2, '0')
	const minPart = min <= 0 ? '' : `${min}:`

	return `${minPart}${secPart}${hideMs ? '' : msPart}`
}


export const secondTimeFormat = (time?: number): string => {
	if (!time) return BlankTime

	const sec = Math.trunc((Math.abs(time) % 60))
	const min = Math.trunc(time / 60)

	return `${min.toString().padStart(2, '0')}:${sec.toString().padStart(2, '0')}`
}