import React from 'react'

import styled from 'styled-components'

import { Cell, Row, VSlider } from '../common'
import { brakeBiasMinMax, diffMinMax } from '../../model'

interface Props {}

export const DynamicSetup: React.FC<Props> = () => {
	return (
		<DynamicSetupContainer id='dynamic-setup'>
			<Row full>
				<Cell flex='1'>
					<VSlider minMax={brakeBiasMinMax} value={50} />
				</Cell>
				<Cell flex='1'>
				<VSlider minMax={diffMinMax} value={55} />
				</Cell>
			</Row>
			<Row full pad='12px 0'>
				<Cell flex='1' text='l'>Brake Bias</Cell>
				<Cell flex='1' text='l'>Differential</Cell>
			</Row>
		</DynamicSetupContainer>
	)
}

const DynamicSetupContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
`
