export const sessionTypeString = (sessionType: number): string => {
	switch (sessionType) {
		case 1: return 'Practice 1'
		case 2: return 'Practice 2'
		case 3: return 'Practice 3'
		case 4: return 'Short Practice'
		case 5: return 'Q1'
		case 6: return 'Q2'
		case 7: return 'Q3'
		case 8: return 'Qualifying'
		case 9: return 'One-shot Qualifying'
		case 10:
		case 11:
		case 12:
			return 'Race'
		case 13: return 'Time Trial'
		case 0:
		default:
			return 'Quay'
	}
}

export const trackNameString = (trackId: number): string => {
	switch (trackId) {
		case 0: return 'Australia'
		case 1: return 'France'
		case 2: return 'China'
		case 3: return 'Bahrain'
		case 4: return 'Spain'
		case 5: return 'Monaco'
		case 6: return 'Montreal'
		case 7: return 'Silverstone'
		case 8: return 'Germany'
		case 9: return 'Hungary'
		case 10: return 'Spa'
		case 11: return 'Monza'
		case 12: return 'Singapore'
		case 13: return 'Japan'
		case 14: return 'Abu Dhabi'
		case 15: return 'Austin'
		case 16: return 'Brazil'
		case 17: return 'Austria'
		case 18: return 'Russia'
		case 19: return 'Mexico'
		case 20: return 'Azerbaijan'
		case 21: return 'Bahrain (Short)'
		case 22: return 'Silverstone (Short)'
		case 23: return 'Austin (Short)'
		case 24: return 'Japan (Short)'
		case 25: return 'Vietnam'
		case 26: return 'Zandvoort'
		case 27: return 'Imola'
		case 28: return 'Portimão'
		case 29: return 'Jeddah'
		case 30: return 'Miami'
		default: return 'You\'re in space'
	}
}

export const safetyCarString = (safetyCarStatus: number): string | null => {
	switch (safetyCarStatus) {
		case 1: return 'Safety Car'
		case 2: return 'VSC'
		case 3: return 'Formation lap'
		case 0:
		default:
			return null
	}
}

export const weatherIconName = (weather: number, timeOfDay: number): string => {
	const isDay = timeOfDay < (18 * 60) // Before 6pm

	switch (weather) {
		case 0: return isDay ? 'clear-day' : 'clear-night'
		case 1: return isDay ? 'cloudy-day' : 'cloudy-night'
		case 2: return 'overcast'
		case 3: return 'light-rain'
		case 4: return 'heavy-rain'
		case 5: return 'storm'
		default: return 'clear-day'
	}
}
