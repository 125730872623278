import React from 'react'
import { useRecoilValue } from 'recoil'

import styled from 'styled-components'

import { Bar, Cell, Row } from '../common'
import { color } from '../../constants'
import { carSetupState, carStatusState } from '../../state'

export const Fuel: React.FC = () => {
	const { fuelInTank: currentFuel, fuelLapsLeft } = useRecoilValue(carStatusState)
	const { fuelLoad } = useRecoilValue(carSetupState)

	const fuelPercent = (currentFuel / fuelLoad) * 100
	const sign = fuelLapsLeft > 0 ? '+' : fuelLapsLeft < 0 ? '-' : ''

	return (
		<FuelContainer>
			<Row pad='4px 0'>
				<Cell flex='2' text='l'>Fuel</Cell>
			</Row>
			<FuelBody direction='column'>
				<Row pad='4px 4px'>
					<Row flex='2'>
						<FuelLapDelta delta={fuelLapsLeft}>
							{sign}{fuelLapsLeft.toFixed(1)} Laps
						</FuelLapDelta>
					</Row>
					<Cell flex='3'>
						<Bar value={fuelPercent} width={150} height={15} />
					</Cell>
				</Row>
			</FuelBody>
		</FuelContainer>
	)
}

const FuelContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	flex: 1;

	border: 2px solid ${color.green};

	width: 100%;

	margin: 4px;
`

const FuelBody = styled(Row)`
	align-items: stretch;
	border-top: none;
`

const FuelLapDelta = styled.span<{ delta: number }>`
	color: ${({ delta }) => {
		if (delta < 0) return color.red
		else if (delta > 0) return color.green
		else return color.white
	}}
`
