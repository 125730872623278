import React from 'react'

import styled from 'styled-components'

import { Row } from './common'
import { Dash } from './dash'
import { Drs } from './drs'
import { Ers } from './ers'
import { Fuel } from './fuel'

interface Props {}

export const ZoomyPanel: React.FC<Props> = () => {
	return (
		<ZoomyPanelContainer id='zoomy'>
			<Row stretch>
				<Drs />
				<Fuel />
			</Row>
			<Row>
				<Ers />
			</Row>
			<Row>
				<Dash />
			</Row>
		</ZoomyPanelContainer>
	)
}

const ZoomyPanelContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
`