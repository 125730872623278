import React, { useMemo } from 'react'

import { useRecoilValue } from 'recoil'
import styled from 'styled-components'

import { CarIconSize, makePositionCalculator, MapSize, Teams, trackMapConfigs, trackMapDebugBounds } from '../../model'
import { carPositionsState, driverInfoState, sessionConstantsState } from '../../state'

import { TrackMapDebug } from './TrackMapDebug'

const Debug = false

export const TrackMap: React.FC = () => {
	const { playerCarIndex, trackId } = useRecoilValue(sessionConstantsState)
	const positions = useRecoilValue(carPositionsState)
	const { drivers } = useRecoilValue(driverInfoState)

	const [positionCalculator, trackName] = useMemo(() => {
		const config = trackMapConfigs[trackId]
		return [makePositionCalculator(config.calculatorConfig, MapSize), config.name]
	}, [trackId])

	return (
		<TrackMapContainer id='track-map' size={MapSize}>
			<img src={`tracks/${trackName}.png`} alt={trackName} />
			{positions.map((p, idx) => {
				const isPlayer = idx === playerCarIndex

				const position = p ?? { x: 0, y: 0, z: 0 }
				const [left, top] = positionCalculator(position.x, position.z)
				const border = isPlayer ? '2px solid white' : 'none'
				const teamId = drivers[idx]?.teamId ?? 0
				const backgroundColor = Teams[teamId]?.color ?? 'black'
				const size = isPlayer ? CarIconSize + 5 : CarIconSize
				const zIndex = isPlayer ? 10 : 1
				return (
					<div
						style={{
							position: 'absolute',
							borderRadius: '50%',
							left: left - (size / 4), // TODO: Idk why 4 works better than 2
							top: top - (size / 4),
							border,
							backgroundColor,
							width: `${size}px`,
							height: `${size}px`,
							zIndex,
						}}
					/>
				)
			})}
			{Debug && (
				<TrackMapDebug
					calculatePosition={positionCalculator}
					bounds={trackMapDebugBounds[trackId] ?? { left: 0, right: 0, top: 0, bottom: 0 }}
					position={positions[playerCarIndex] ?? { x: 0, y: 0, z: 0 }}
				/>
			)}
		</TrackMapContainer>
	)
}

const TrackMapContainer = styled.div<{ size: number }>`
	display: flex;
	flex-direction: column;

	
	position: relative;

	width: ${props => props.size}px;
`
