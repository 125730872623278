import { useEffect } from 'react'
import { useSetRecoilState } from 'recoil'
import io from 'socket.io-client'

import { assistInfoState, bestLapDataState, carDamageState, carSetupState, lapDataState, lastLapDataState, sessionConstantsState, sessionInfoState, telemetryState, wheelDataState, tireStatusState, carStatusState, liveLapDataState, driverInfoState, carPositionsState, pitStopInfoState, leaderDeltaState, intervalDeltaState, playerDeltaState, wearDataState, averageWearState, stintCompoundsState, currentStintState, playerPositionState, carMotionState, batteryStatusState } from './atoms'
import { TelemetryIOSocket } from '../model/common'
import { BaseUrl } from '../constants'

export function useTelemetry() {
	const setCarStatusState = useSetRecoilState(carStatusState)
	const setBatteryStatusState = useSetRecoilState(batteryStatusState)
	const setCarMotionState = useSetRecoilState(carMotionState)
	const setTelemetryState = useSetRecoilState(telemetryState)
	const setWheelDataState = useSetRecoilState(wheelDataState)
	const setCarDamageState = useSetRecoilState(carDamageState)
	const setSessionConstantsState = useSetRecoilState(sessionConstantsState)
	const setLapTimeState = useSetRecoilState(lapDataState)
	const setLiveLapDataState = useSetRecoilState(liveLapDataState)
	const setLastLapDataState = useSetRecoilState(lastLapDataState)
	const setBestLapDataState = useSetRecoilState(bestLapDataState)
	const setAssistInfoState = useSetRecoilState(assistInfoState)
	const setCarSetupState = useSetRecoilState(carSetupState)
	const setTireStatusState = useSetRecoilState(tireStatusState)
	const setDriverInfoState = useSetRecoilState(driverInfoState)
	const setSessionInfoState = useSetRecoilState(sessionInfoState)
	const setCarPositionsState = useSetRecoilState(carPositionsState)
	const setPitStopInfoState = useSetRecoilState(pitStopInfoState)
	const setLeaderDeltasState = useSetRecoilState(leaderDeltaState)
	const setIntervalDeltasState = useSetRecoilState(intervalDeltaState)
	const setPlayerDeltasState = useSetRecoilState(playerDeltaState)
	const setWearDataState = useSetRecoilState(wearDataState)
	const setAverageWearState = useSetRecoilState(averageWearState)
	const setStintCompoundsState = useSetRecoilState(stintCompoundsState)
	const setCurrentStintState = useSetRecoilState(currentStintState)

	const setPlayerPositionState = useSetRecoilState(playerPositionState)

	useEffect(() => {
		const socket: TelemetryIOSocket = io(BaseUrl)

		socket.on('sessionConstants', data => {
			setSessionConstantsState(data)
		})

		socket.on('carStatus', data => {
			setCarStatusState(data)
		})

		socket.on('batteryStatus', data => {
			setBatteryStatusState(data)
		})

		socket.on('carTelemetry', data => {
			setTelemetryState(data)
		})

		socket.on('carDamage', data => {
			setCarDamageState(prev => ({
				...prev,
				wing: {
					left: data.frontLeftWingDamage,
					right: data.frontRightWingDamage,
				},
				floor: data.floorDamage,
				sidepod: data.sidepodDamage,
				diffuser: data.diffuserDamage,
				rearWing: data.rearWingDamage,
				tireWear: data.tireWear,
				gearbox: data.gearboxDamage,
				engine: data.engineDamage,
			}))
		})

		socket.on('lapData', data => {
			// TODO: Handle more than 20 cars
			setLapTimeState(data.slice(0, 20))
		})

		socket.on('bestLapData', data => {
			console.log('bestLapData', data)
			setBestLapDataState(data)
		})

		socket.on('lastLapData', data => {
			setLastLapDataState(data)
		})

		socket.on('liveLapData', data => {
			setLiveLapDataState(data)
		})

		socket.on('assistInfo', data => {
			setAssistInfoState(data)
		})

		socket.on('carSetup', data => {
			setCarSetupState(data)
		})

		socket.on('tireStatus', data => {
			setTireStatusState(data.slice(0, 20))
		})

		socket.on('sessionInfo', data => {
			setSessionInfoState(data)
		})

		socket.on('driverInfo', data => {
			setDriverInfoState(data)
		})

		socket.on('wheelData', data => {
			setWheelDataState(data)
		})

		socket.on('carPositions', data => {
			setCarPositionsState(data)

			setPlayerPositionState(prev => [...prev, data[19]])
		})

		socket.on('carMotion', data => {
			setCarMotionState(data)
		})

		socket.on('pitStopInfo', data => {
			setPitStopInfoState(data)
		})

		socket.on('leaderDeltas', data => {
			setLeaderDeltasState(data)
		})

		socket.on('intervalDeltas', data => {
			setIntervalDeltasState(data)
		})

		socket.on('playerDeltas', data => {
			setPlayerDeltasState(data)
		})

		socket.on('wearDataPoint', data => {
			setWearDataState(prev => [...prev, data])
		})

		socket.on('averageWear', data => {
			setAverageWearState(data)
		})

		socket.on('stintCompounds', data => {
			setStintCompoundsState(data)
		})

		socket.on('currentStint', data => {
			setCurrentStintState(data)
		})

		// =================
		// Requests on load
		// =================
		socket.emit('driverInfo', data => {
			setDriverInfoState(data)
		})

		socket.emit('carSetup', data => {
			setCarSetupState(data)
		})

		socket.emit('sessionConstants', data => {
			setSessionConstantsState(data)
		})

		socket.emit('sessionInfo', data => {
			setSessionInfoState(data)
		})

		socket.emit('bestLapData', data => {
			setBestLapDataState(data)
		})

		socket.emit('lastLapData', data => {
			setLastLapDataState(data)
		})

		socket.emit('pitInfo', data => {
			setPitStopInfoState(data)
		})

		socket.emit('assistInfo', data => {
			setAssistInfoState(data)
		})

		socket.emit('wearHistory', data => {
			setWearDataState(data)
		})

		socket.emit('stintCompounds', data => {
			setStintCompoundsState(data)
		})

		socket.emit('currentStint', data => {
			setCurrentStintState(data)
		})

		return () => {
			socket.disconnect()
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

}