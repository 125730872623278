import { TireData } from '../model/common'

export type CarDirection = CarSide | CarEnd
export type CarSide = 'left' | 'right'
export type CarEnd = 'front' | 'rear'

export interface TireSet<T> {
	fl: T
	fr: T
	rl: T
	rr: T
}

export const TireSet = <T>(value: T): TireSet<T> => ({
	fl: value, fr: value, rl: value, rr: value
})

export interface EndSet<T> {
	front: T
	rear: T
}

export const EndSet = <T>(value: T): EndSet<T> => ({
	front: value, rear: value
})

export interface SideSet<T> {
	left: T
	right: T
}

export const SideSet = <T>(value: T): SideSet<T> => ({
	left: value, right: value
})

export interface MinMax<T> {
	min: T
	max: T
}

export const MinMax = <T>(min: T, max: T): MinMax<T> => ({ min, max })

export interface CarSetupState {
	aero: EndSet<number>
	diff: {
		onThrottle: number
		offThrottle: number
	}
	geometry: {
		camber: EndSet<number>
		toe: EndSet<number>
	}
	suspension: {
		suspension: EndSet<number>
		antiRollBar: EndSet<number>
		rideHeight: EndSet<number>
	}
	brake: {
		pressure: number
		bias: number
	}
	tirePressure: EndSet<SideSet<number>>
}

export const aeroMinMax = MinMax(0, 50)
export const diffMinMax = MinMax(50, 100)
export const frontCamberMinMax = MinMax(-3.5, -2.5)
export const rearCamberMinMax = MinMax(-2, -1)
export const frontToeMinMax = MinMax(0.05, 0.15)
export const rearToeMinMax = MinMax(0.20, 0.50)
export const suspensionMinMax = MinMax(1, 11)
export const brakePressureMinMax = MinMax(50, 100)
export const brakeBiasMinMax = MinMax(50, 70)
export const frontTireMinMax = MinMax(21, 25)
export const rearTireMinMax = MinMax(19.5, 23.5)
export const ballastMinMax = MinMax(0, 10)

export interface CarDamageState {
	wing: SideSet<number>
	floor: number
	sidepod: number
	gearbox: number
	engine: number
	diffuser: number
	rearWing: number
	tireDamage: TireData
	tireWear: TireData
}
