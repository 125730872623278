import React from 'react'
import { useRecoilValue } from 'recoil'

import styled from 'styled-components'
import { bestLapDataState, lastLapDataState, liveLapDataState, playerLapDataSelector } from '../../state'
import { SectorTimes } from './SectorTimes'
import { Standing } from './Standing'

interface Props {
}

export const RaceData: React.FC<Props> = () => {
	const { currentSector } = useRecoilValue(playerLapDataSelector)
	const { lapTime: currentLapTime, sector1Time, sector2Time } = useRecoilValue(liveLapDataState)
	const lastLap = useRecoilValue(lastLapDataState)
	const { personalBest, sessionBest } = useRecoilValue(bestLapDataState)

	const sector3Time = currentSector === 3
		? currentLapTime - sector1Time - sector2Time
		: null

	return (
		<RaceDataContainer id='race-data'>
			<Standing />
			<SectorTimes
				heading='Last Lap'
				current={lastLap}
				sessionBest={sessionBest}
				personalBest={personalBest}
				currentSector={currentSector}
				isCurrentLap={false}
			/>
			<SectorTimes
				heading='Current Lap'
				current={{ time: currentLapTime, s1: sector1Time, s2: sector2Time, s3: sector3Time }}
				sessionBest={sessionBest}
				personalBest={personalBest}
				currentSector={currentSector}
				isCurrentLap={true}
			/>
		</RaceDataContainer>
	)
}

const RaceDataContainer = styled.div`
	display: flex;
	flex-direction: column;
`
