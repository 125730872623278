import { Color } from './Color'
import { makeColorInterpolator } from './colorInterpolator'

import { color } from '../../constants'

export const tireSurfaceTempColorInterpolator = makeColorInterpolator([
	{ value: 50, color: Color.fromHex(color.blueLight) },
	{ value: 65, color: Color.fromHex(color.green) },
	{ value: 130, color: Color.fromHex(color.green) },
	{ value: 140, color: Color.fromHex(color.yellow) },
	{ value: 150, color: Color.fromHex(color.red) },
])

export const tireCarcassTempColorInterpolator = makeColorInterpolator([
	{ value: 70, color: Color.fromHex(color.blueLight) },
	{ value: 80, color: Color.fromHex(color.green) },
	{ value: 105, color: Color.fromHex(color.yellow) },
	{ value: 110, color: Color.fromHex(color.red) },
])

export const tireWearColorInterpolator = makeColorInterpolator([
	{ value: 0, color: Color.fromHex(color.green) },
	{ value: 30, color: Color.fromHex(color.yellow) },
	{ value: 50, color: Color.fromHex(color.red) },
])

export const brakeTempColorInterpolator = makeColorInterpolator([
	{ value: 650, color: Color.fromHex(color.green) },
	{ value: 850, color: Color.fromHex(color.yellow) },
	{ value: 1100, color: Color.fromHex(color.red) },
])

export const engineTempColorInterpolator = makeColorInterpolator([
	{ value: 80, color: Color.fromHex(color.blueLight) },
	{ value: 105, color: Color.fromHex(color.green) },
	{ value: 115, color: Color.fromHex(color.yellow) },
	{ value: 130, color: Color.fromHex(color.red) },
])

export const damageColorInterpolator = makeColorInterpolator([
	{ value: 20, color: Color.fromHex(color.green) },
	{ value: 50, color: Color.fromHex(color.yellow) },
	{ value: 100, color: Color.fromHex(color.red) },
])
