import React from 'react'

import styled from 'styled-components'

import { CarDiagram } from './CarDiagram'
import { CarSetupView } from './carSetup'
import { Leaderboard } from './leaderboard'
import { PitInfo, RaceData } from './raceData'
import { SessionInfo } from './session'
import { Wheels } from './tires'
import { TrackMap } from './trackMap'
import { ZoomyPanel } from './ZoomyPanel'

import { useTelemetry } from '../state'

export const App: React.FC = () => {
	useTelemetry()

	return (
		<AppContainer id='app-container'>
			<Leaderboard />
			<ZoomyPanel />
			<CarSetupView />
			<PitInfo />
			{/* <CarDiagram /> */}
			<TrackMap />
			<Wheels />
			<RaceData />
			<SessionInfo />
		</AppContainer>
	)
}

const AppContainer = styled.div`

`
