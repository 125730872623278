import { atom, selector, selectorFamily } from 'recoil'

import { TireData, SessionConstants, BestLapData, AssistInfo, CarMotion, Vec3, LapData, LastLapData, CarSetup, TireStatus, SessionInfo, DriverInfo, CarTelemetry, WheelData, CarStatus, LiveLapData, Delta, PitStopInfo, EmptyDelta, WearData, TireCompound, BatteryStatus } from '../model/common'
import { CarDamageState, LeaderboardDriver, SideSet, TireCompoundType } from '../model'

export const telemetryState = atom<CarTelemetry>({
	key: 'telemetryState',
	default: {
		speed: 0,
		throttle: 0,
		steer: 0,
		brake: 0,
		clutch: 0,
		gear: 0,
		rpm: 0,
		revLights: 0,
		drs: false,
	}
})

export const wheelDataState = atom<WheelData>({
	key: 'wheelDataState',
	default: {
		engineTemp: 0,
		tireSurfaceTemps: TireData(0),
		tireCarcassTemps: TireData(0),
		brakeTemps: TireData(0),
		tirePressures: TireData(0),
		tireSurfaceTypes: TireData(0),
	}
})

export const carDamageState = atom<CarDamageState>({
	key: 'carDamageState',
	default: {
		wing: SideSet(0),
		floor: 0,
		sidepod: 0,
		gearbox: 0,
		engine: 0,
		diffuser: 0,
		rearWing: 0,
		tireDamage: TireData(0),
		tireWear: TireData(0),
	}
})

export const sessionConstantsState = atom<SessionConstants>({
	key: 'sessionConstantsState',
	default: {
		sessionDuration: 0,
		sessionType: 0,
		aiDifficulty: 0,
		totalLaps: 0,
		pitSpeedLimit: 0,
		playerCarIndex: 0,
		trackId: 0,
		trackLength: 0,
		timeOfDay: 0,
	}
})

export const driverInfoState = atom<DriverInfo>({
	key: 'driverInfoState',
	default: {
		numCars: 0,
		drivers: [],
	},
})

export const carStatusState = atom<CarStatus>({
	key: 'carStatusState',
	default: {
		fuelMix: 0,
		brakeBias: 0,
		pitLimiter: false,
		fuelInTank: 0,
		fuelLapsLeft: 0,
		drsAllowed: false,
		drsActivationDistance: 0,
		ersStoreEnergy: 0,
		ersDeployMode: 0,
		ersHarvestedThisLapMGUK: 0,
		ersHarvestedThisLapMGUH: 0,
		ersDeployedThisLap: 0,
	}
})

export const batteryStatusState = atom<Array<BatteryStatus>>({
	key: 'batteryStatusState',
	default: [],
})

export const sessionInfoState = atom<SessionInfo>({
	key: 'sessionInfoState',
	default: {
		weather: 0,
		trackTemp: 0,
		airTemp: 0,
		sessionTimeLeft: 0,
		safetyCarStatus: 0,
		drsEnabled: false,
	}
})

const DefaultLapData: LapData = {
	lastLapTime: 0,
	currentLapInvalid: false,
	currentSector: 1,
	racePosition: 0,
	currentLap: 0,
	penalties: 0,
	warnings: 0,
	gridPosition: 0,
	numStops: 0,
	pitStatus: 0,
	totalPitTime: 0,
	pitStopTime: 0,
	driverStatus: 0,
	resultStatus: 0,
}

export const lapDataState = atom<Array<LapData>>({
	key: 'lapTimeState',
	default: [],
})

export const liveLapDataState = atom<LiveLapData>({
	key: 'liveLapDataState',
	default: {
		lapTime: 0,
		sector1Time: 0,
		sector2Time: 0,
	}
})

export const lapDataSelector = selectorFamily<LapData, number>({
	key: 'lapDataSelector',
	get: (carIndex: number) => ({ get }) => {
		const lapData = get(lapDataState)
		return lapData[carIndex] ?? DefaultLapData
	},
})

export const playerLapDataSelector = selector<LapData>({
	key: 'playerLapDataSelector',
	get: ({ get }) => {
		const sessionConstants = get(sessionConstantsState)
		return get(lapDataSelector(sessionConstants.playerCarIndex))
	}
})

export const bestLapDataState = atom<BestLapData>({
	key: 'bestLapDataState',
	default: {
		personalBest: { time: null, s1: null, s2: null, s3: null },
		sessionBest: { time: null, s1: null, s2: null, s3: null },
		sessionBestCarIdx: null,
	}
})

export const lastLapDataState = atom<LastLapData | null>({
	key: 'lastLapDataState',
	default: null,
})

export const assistInfoState = atom<AssistInfo>({
	key: 'assistInfoState',
	default: {
		steeringAssist: false,
		brakingAssist: 0,
		gearboxAssist: 0,
		pitAssist: false,
		pitReleaseAssist: false,
		ersAssist: false,
		drsAssist: false,
		racingLineAssist: 0,
		tractionControl: 0,
		abs: false,
	}
})

export const carMotionState = atom<CarMotion>({
	key: 'carMotionState',
	default: {
		gForce: Vec3(0),
		orientation: Vec3(0),
		suspensionPosition: TireData(0),
		suspensionVelocity: TireData(0),
		suspensionAcceleration: TireData(0),
		wheelSlip: TireData(0),
		wheelSpeed: TireData(0),
		velocity: Vec3(0),
		angularVelocity: Vec3(0),
		angularAcceleration: Vec3(0),
		frontWheelsAngle: 0,
	}
})

export const carPositionsState = atom<Array<Vec3>>({
	key: 'carPositionsState',
	default: [],
})

export const playerPositionState = atom<Array<Vec3>>({
	key: 'playerPositionState',
	default: [],
})

export const carPositionSelector = selectorFamily({
	key: 'carPositionSelector',
	get: (carIndex: number) => ({ get }) => {
		const positions = get(carPositionsState)
		return positions[carIndex] ?? Vec3(0)
	}
})

export const carSetupState = atom<CarSetup>({
	key: 'carSetupState',
	default: {
		frontWing: 0,
		rearWing: 0,
		diffOnThrottle: 0,
		diffOffThrottle: 0,
		frontCamber: 0,
		rearCamber: 0,
		frontToe: 0,
		rearToe: 0,
		frontSuspension: 0,
		rearSuspension: 0,
		frontAntiRollBar: 0,
		rearAntiRollBar: 0,
		frontSuspensionHeight: 0,
		rearSuspensionHeight: 0,
		brakePressure: 0,
		brakeBias: 0,
		tirePressures: TireData(0),
		ballast: 0,
		fuelLoad: 0,
	}
})

export const tireStatusState = atom<Array<TireStatus>>({
	key: 'tireStatusState',
	default: [],
})

const DefaultTireStatus: TireStatus = {
	compound: 0,
	age: 0
}

export const tireStatusSelector = selectorFamily<TireStatus, number>({
	key: 'tireStatusSelector',
	get: (carIndex: number) => ({ get }): TireStatus => {
		const tireStatuses = get(tireStatusState)
		return tireStatuses[carIndex] ?? DefaultTireStatus
	}
})

export const playerTireStatus = selector<TireStatus>({
	key: 'playerTireStatus',
	get: ({ get }) => {
		const sessionConstants = get(sessionConstantsState)
		return get(tireStatusSelector(sessionConstants.playerCarIndex))
	}
})

export const leaderDeltaState = atom<Array<Delta>>({
	key: 'leaderDeltaState',
	default: [],
})

export const intervalDeltaState = atom<Array<Delta>>({
	key: 'intervalDeltaState',
	default: [],
})

export const playerDeltaState = atom<Array<Delta>>({
	key: 'playerDeltaState',
	default: [],
})

export const leaderboardSelector = selector<Array<LeaderboardDriver>>({
	key: 'leaderboardSelector',
	get: ({ get }): Array<LeaderboardDriver> => {
		const { drivers } = get(driverInfoState)
		const lapData = get(lapDataState)
		const tireStatus = get(tireStatusState)
		const leaderDeltas = get(leaderDeltaState)
		const intervalDeltas = get(intervalDeltaState)
		const playerDeltas = get(playerDeltaState)
		const batteryStatus = get(batteryStatusState)

		const leaderboardDrivers = lapData
			.map((ld, i) => [ld, i] as [LapData, number])
			.sort(([a], [b]) => a.racePosition - b.racePosition)
			.map(([ld, i]) => {
				const { numStops, warnings, penalties, driverStatus, resultStatus, pitStatus } = ld
				const driverInfo = drivers[i]
				const tire = tireStatus[i]
				const driver: LeaderboardDriver = {
					driverIdx: i,
					isAi: driverInfo.aiControlled,
					name: driverInfo?.driverName ?? '',
					gridPosition: ld.gridPosition,
					leaderDelta: leaderDeltas[i] ?? EmptyDelta,
					intervalDelta: intervalDeltas[i] ?? EmptyDelta,
					playerDelta: playerDeltas[i] ?? EmptyDelta,
					numStops,
					warnings,
					penalties,
					tireCompound: tire?.compound ? TireCompoundType(tire.compound) : TireCompoundType(0),
					tireAge: tire?.age ?? 0,
					driverStatus,
					resultStatus,
					pitStatus,
					batteryStatus: batteryStatus[i] ?? { energy: 0, ersDeployMode: 1 }
				}

				return driver
			})

			return leaderboardDrivers
	}
})

export const pitStopInfoState = atom<PitStopInfo>({
	key: 'pitStopInfoState',
	default: {
		pitWindowIdealLap: 0,
		pitWindowLatestLap: 0,
		pitStopRejoinPosition: 0,
	},
})

export const wearDataState = atom<Array<WearData>>({
	key: 'wearDataState',
	default: [],
})

export const averageWearState = atom<number>({
	key: 'averageWearState',
	default: 0,
})

export const currentStintState = atom<number>({
	key: 'currentStintState',
	default: 1,
})

export const stintCompoundsState = atom<Array<TireCompound>>({
	key: 'stintCompoundsState',
	default: [],
})

export const averageWearLineSegment = selector<Array<{ x: number, y: number }>>({
	key: 'averageWearLineSegment',
	get: ({ get }) => {
		const data = get(wearDataState)

		if (data.length === 0) {
			return []
		}

		const { totalLaps } = get(sessionConstantsState)
		const averageWear = get(averageWearState)
		const currentStint = get(currentStintState)

		const lastPoint = data[data.length - 1]
		const x1 = lastPoint.x
		const y1 = lastPoint[currentStint] ?? 0
		const wearTarget = 30 // y2
		const checkerLap = totalLaps + 1
		const predictedEndLap = x1 + ((y1 - wearTarget) / averageWear) // x2
		const endWear = y1 - averageWear * (checkerLap - x1) // y2
	
		const [x2, y2] = predictedEndLap <= checkerLap
			? [predictedEndLap, wearTarget]
			: [checkerLap, endWear]

		return [{ x: x1, y: y1 }, { x: x2, y: y2 }]
	}
})

const LockUpWheelMaxSpeed = 0.1

export const wheelsLocked = selector<boolean>({
	key: 'wheelsLocked',
	get: ({ get }) => {
		const { speed } = get(telemetryState)
		const { wheelSpeed } = get(carMotionState)

		return speed > 0 && (wheelSpeed ?? []).some(ws => ws < LockUpWheelMaxSpeed)
	}
})

export const wheelLockUpSelector = selectorFamily<boolean, number>({
	key: 'wheelLockUpSelector',
	get: (wheelIndex: number) => ({ get }) => {
		const { speed } = get(telemetryState)
		const motion = get(carMotionState)
		const wheelSpeed = motion.wheelSpeed?.[wheelIndex] ?? 0

		return speed > 0 && wheelSpeed < LockUpWheelMaxSpeed
	}
})

export const wheelSlipSelector = selectorFamily<number, number>({
	key: 'wheelSlipSelector',
	get: (wheelIndex: number) => ({ get }) => {
		const motion = get(carMotionState)
		return motion.wheelSlip?.[wheelIndex] ?? 0
	}
})
